import React, { Component } from "react";
import {Link} from "react-router-dom"
import { baseUrl } from "../const_var";
import axios from "axios";

export default class SignUp extends Component {
    constructor(props){
      super(props)
      this.state = {
        error: "",

      }

      this.handleRegister = this.handleRegister.bind(this)
      this.handleChange = this.handleChange.bind(this)
    }

    handleRegister = () =>{

      console.log("Data:: ", this.state,"baseUrl:: ",baseUrl)

	const firstName= this.state.firstName.toLowerCase()
	const lastName= this.state.lastName.toLowerCase()
	const email= this.state.email.toLowerCase()
	const phoneNumber= this.state.phoneNumber
	const password=this.state.password

      axios.post(`${baseUrl}/auth/wave/users/register`,{
        firstName,lastName,email,password,phoneNumber
      }).then(response =>{

        console.log("response::",response.data.access_data)
        // console.log(" response.data.data.access_data::", response.data.data.access_data)

       
        if(response.data.access_data){
          localStorage.setItem("userToken", response.data.access_data.token);
            window.location = "/dashboard";
      
        }

      }).catch(e => {
        
        console.log("ERROR::: ",e)
        console.log("ERROR::",e)
        this.setState({error:e.response.data.message
  
         })
         window.location = "/dashboard";

      }) 
    }

 handleChange = (e) => {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    };

  render() {
    return (
      <div>
        <div className="text-center text-2xl font-bold py-2">
          Create your account
        </div>
        {this.state.error &&
          <div className=" rounded p-3 text-red-500 text-center font-semi-bold bg-white">{ this.state.error }</div>
        }


        <div className="max-w-md mx-auto border p-3 rounded border-gray-500">
          <div class="relative z-0 w-full mb-5 group">
            <input
            onChange={this.handleChange}
              type="email"
              name="email"
              id="email"
              placeholder=" "
              required
              className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" "  />

            <label
              for="email"
              class="peer-focus:font-medium absolute text-sm  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
            >
              Email address
            </label>
          </div>
          <div class="relative z-0 w-full mb-5 group">
            <input
            onChange={this.handleChange}
              type="password"
              name="password"
              id="password"
              placeholder=" "
              required
              className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" "  />

            <label
              for="password"
              class="peer-focus:font-medium absolute text-sm  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
            >
              Password
            </label>
          </div>
          <div class="relative z-0 w-full mb-5 group">
            <input
            onChange={this.handleChange}
              type="password"
              name="repeat_password"
              id="repeat_password"
              placeholder=" "
              required
              className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" "  />

            <label
              for="repeat_password"
              class="peer-focus:font-medium absolute text-sm  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
            >
              Confirm password
            </label>
          </div>
          <div class="grid md:grid-cols-2 md:gap-6">
            <div class="relative z-0 w-full mb-5 group">
              <input
              onChange={this.handleChange}
                type="text"
                name="firstName"
                id="first_name"
                placeholder=" "
                required
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" "  />

              <label
                for="first_name"
                class="peer-focus:font-medium absolute text-sm  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                First name
              </label>
            </div>
            <div class="relative z-0 w-full mb-5 group">
              <input
              onChange={this.handleChange}
                type="text"
                name="lastName"
                id="last_name"
                placeholder=" "
                required
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" "  />

              <label
                for="last_name"
                class="peer-focus:font-medium absolute text-sm  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Last name
              </label>
            </div>
          </div>
          <div class="grid md:grid-cols-2 md:gap-6">
            <div class="relative z-0 w-full mb-5 group">
              <input
              onChange={this.handleChange}
                type="tel"
                // pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                name="phoneNumber"
                id="phone"
                placeholder=" "
                required
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" "  />

              <label
                for="phone"
                class="peer-focus:font-medium absolute text-sm  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Phone number 
              </label>
            </div>
            <div class="relative z-0 w-full mb-5 group">
             
               <label for="gender" class="block mb-2 text-sm font-medium ">

                Gender
              </label>

              <select id="gender" onChange={this.handleChange} name="gender"
        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer" >
        <option value={null}>Pick a sex</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </div>
          </div>
          <div className='className=" mx-auto text-center'>
            <button
              // type="submit"
              onClick={this.handleRegister}
              class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full  px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Submit
            </button>
          </div>
        <p className="my-[15px] text-center text-[14px]">
            Have An Account Already?{" "}
            <span>
              <Link to="/login"

                className="text-blue-700 font-semibold cursor-pointer"
              >
                Sign In
              </Link>
            </span>
          </p>
        </div>
      </div>
    );
  }
}
