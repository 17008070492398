import React, { Component } from "react";
import axios from "axios";
import { baseUrl } from "../const_var";

export default class ShippingCalculator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      success: false,
      customerDetails: {
        customerName: "",
        dateDropped: "",
        parcelWeight: "",
        status: "",
      },
    };
    this.handleGet = this.handleGet.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleReset = this.handleReset.bind(this);
  }

  componentDidMount() {
    window.scroll(0, 0);
  }

  getShippings = () => {
    axios.get(`${baseUrl}/logistics`).then((res) => {
      console.log("res", baseUrl, "res", res);
    });
  };


  handleGet = () => {
    console.log("STTAE::::",this.state)
   
  };

  handleReset() {
    this.setState({
      Shipping: "",
    });
  }

  closeModal() {
    // Close the modal and reset the state
    this.setState({
      error: false,
      success: false,
      message: "",
    });

    // Close the modal programmatically
    document.getElementById("showMessageModal")?.close();
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    return (
      // <div className="w-full h-[100vh]  font-[Roboto-Serif] flex md:gap-x-5 gap-y-0 flex-col px-[20px]  md:px-[100px] items-center">
      <div className="max-w-xl mx-auto bg-white p-6 rounded-lg shadow-md bg-gray-100 p-10">
        <div className=" px-4 py-3  mx-2 rounded ">
          <h2 className="font-bold text-[40px]  ">Get a price, instantly!</h2>
          <p>
            Assured goodbye to hidden rate cards as you use our calculator to
            get an instant estimate.
          </p>
        </div>

        <div className="space-y-4">
          {/* <!-- First field (separate row) --> */}
          <div>
            <label
              for="pickUpLocation"
              className="block text-sm font-medium text-gray-700"
            >
              Pickup Location
            </label>
            <input
              onChange={this.handleChange}
              type="text"
              id="pickUpLocation"
              name="pickUpLocation"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
              placeholder="Enter your PickUp Location"
            />
          </div>

          {/* <!-- Second field (separate row) --> */}
          <div>
            <label
              for="deliveryLocation"
              className="block text-sm font-medium text-gray-700"
            >
              Delivery Location
            </label>
            <input
              onChange={this.handleChange}
              type="text"
              id="deliveryLocation"
              name="deliveryLocation"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
              placeholder="Where would you like us to deliver you items?"
            />
          </div>

          {/* <!-- Last three fields on the same row --> */}
          <div className="grid grid-cols-3 gap-4">
            <div>
              <label
                for="quantity"
                className="block text-sm font-medium text-gray-700"
              >
                Quantity
              </label>
              <input
                onChange={this.handleChange}
                type="numeric"
                min={1}
                id="quantity"
                name="quantity"
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                placeholder="eg: 2"               
                />
            </div>

            <div>
              <label
                for="weight"
                className="block text-sm font-medium text-gray-700"
              >
                Weight
              </label>
              <input
                onChange={this.handleChange}
                type="numeric"
                id="weight"
                name="weight"
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                placeholder="Enter weight (in KG)"
              />
            </div>

            <div>
              <label
                for="volume"
                className="block text-sm font-medium text-gray-700"
              >
                Volume        
              </label>
              <input
                onChange={this.handleChange}
                type="text"
                id="volume"
                name="volume"
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                placeholder="volume in cubic-meter"
              />
              
            </div>
          </div>

          {/* <!-- Submit button --> */}
          <div>
            <button 
              onClick={this.handleGet}
              className="w-full bg-indigo-600 text-white p-2 rounded-md hover:bg-indigo-700 focus:ring-2 focus:ring-indigo-500">
            Get a Price{" "}
            </button>
          </div>
        </div>
        {/* </div> */}
      </div>
    );
  }
}
