import React, { Component } from 'react'

export default class TermsPage extends Component {
  render() {
    return (
        <div className="max-w-sm mx-auto  mb-5">
        <div className='text-center text-lg font-bold'>
            <h1>Terms and Agreement</h1>
        </div>
        </div>
    )
  }
}
