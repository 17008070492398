import React, { Component } from 'react'
import {Link} from "react-router-dom"
import axios from "axios";
import { baseUrl } from "../const_var";

export default class Login extends Component {
    constructor(props){
        super(props)
        this.state = {}
        this.handleSignIn = this.handleSignIn.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }
    componentDidMount(){
      const token = localStorage.getItem("userToken")
      if(token != undefined) return window.location = "/shipping";
    }
    handleSignIn = () =>{

      console.log("Data:: ", this.state)

      const email= this.state.email.toLowerCase()
      const password=this.state.password
    
      axios.post(`${baseUrl}/auth/wave/users`,{
       email,password
      }).then(response =>{
        
       
        if(response.data.access_data){
          localStorage.setItem("userToken", response.data.access_data.access_token);
          localStorage.setItem("role", response.data.access_data.role);
          window.location = "/dashboard";
      
        }

      }).catch(e => {
        
        console.log("ERROR::: ",e)
        console.log("ERROR::",e)
        this.setState({error:e.response.data.message
  
         })
      }) 
    }

    handleChange = (e) => {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    };
  render() {
    return (
      <div className='mx-auto'>
        

        <div className='text-center text-2xl font-bold py-2'>
            Login to your account
        </div>

        {this.state.error &&

          <div className=" rounded p-3 text-red-500 text-center font-semi-bold bg-white">{ this.state.error }</div>
        }

<div  className="max-w-md mx-auto border p-3 rounded border-gray-500">
  <div className="relative z-0 w-full mb-5 group">
      <input onChange={this.handleChange}  type="email" name="email" id="email" 
        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" "  />
      <label htmlFor="email" className="peer-focus:font-medium absolute text-sm text-black-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Email address</label>
  </div>
  <div className="relative z-0 w-full mb-5 group">
      <input onChange={this.handleChange} type="password" name="password" id="password" 
        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" "  />
        <label htmlFor="password" className="peer-focus:font-medium absolute text-sm  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Password</label>
  </div>
 <div className='className=" mx-auto text-center'>
    <button onClick={this.handleSignIn}  className=" text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full  px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Login</button>
 </div>
<p className="my-[15px] text-center text-[14px]">
            Don't Have An Account?{" "}
            <span className="text-blue-700">
              <Link
                to="/signup"
                className="font-semibold cursor-pointer"
              >
                Sign Up
              </Link>
            </span>
          </p>

</div>
      </div>
    )
  }
}
