import React, { Component } from 'react'
import { baseUrl } from '../const_var';
import axios from "axios";

export default class Dashboard extends Component {
    constructor(props){
        super(props)
        this.state={
            calculatedPrice:2000,
            termsAgreement:false,
            currency:"USD",
            freightItems:[],
            freightWeight:0
        }

        // this.getList = this.getList.bind(this);
        // this.fetchData = this.fetchData.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.token = localStorage.getItem("userToken")

    }

    componentDidMount(){

      if(!this.token){
        window.location="/login"
      }
        // this.getList()
    }

    // async fetchData(item){
    //     const pathRef = ref(
    //         database,
    //         "uwave/" + item
    //       );
      
    //     const snapshot = await get(pathRef);

    //     if (snapshot.exists()) {
    //         let list =[]
    //         snapshot.forEach((childSnapshot) => {

    //             list.push(childSnapshot.val())
    //             console.log(" FOUND",childSnapshot.val())
                
    //         })
    //         this.setState({customerDataList:list})
        
    //     }else{
    //         console.log("NOT FOUND")
    //     }
    // }

    // fetchData2(item){
    //     const db = getDatabase()
    //     // return console.log("this.state.date",item);
    //     // return console.log(this.state.date);

    //     const starCountRef = ref(db, `uwave/ ${this.state.date}`);
    //     onValue(starCountRef, (snapshot) => {
    //         const data = snapshot.val(); // Get the data from the snapshot
    //         console.log("data:: ",data)
      
    //         if (data) {
                
    //             // const customerDate = Object.keys(data);
    //             // this.setState({
    //             //     customerDate,
    //             // })
           
              
    //         //   // Convert the data into an array
    //           const customerDataList = Object.values(data); // Convert the data into an array
              
    //           this.setState({
    //             customerDataList, // Store the list in the state
    //             error: false,
    //             errorMessage: '',
    //           });
    //         } else {
    //           // If there's no data, set an error
    //           this.setState({
    //             error: true,
    //             errorMessage: 'No customer data found.',
    //           });
    //         }
    //       }, (errorObject) => {
    //         // Handle the error from Firebase
    //         this.setState({
    //           error: true,
    //           errorMessage: `Error fetching data: ${errorObject.message}`,
    //         });
    //       });
    // }


    // getList(){
    //     const db = getDatabase()

    //     const starCountRef = ref(db, 'uwave/');
    //     onValue(starCountRef, (snapshot) => {
    //         const data = snapshot.val(); // Get the data from the snapshot
      
    //         if (data) {
                
    //             const customerDate = Object.keys(data);
    //             this.setState({
    //                 customerDate,
    //             })
           
    //         //     console.log("customerDate:: ",customerDate)
              
    //         //   // Convert the data into an array
    //         //   const customerDataList = Object.values(data); // Convert the data into an array
    //         //   this.setState({
    //         //     customerDataList, // Store the list in the state
    //         //     error: false,
    //         //     errorMessage: '',
    //         //   });
    //         } else {
    //           // If there's no data, set an error
    //           this.setState({
    //             error: true,
    //             errorMessage: 'No customer data found.',
    //           });
    //         }
    //       }, (errorObject) => {
    //         // Handle the error from Firebase
    //         this.setState({
    //           error: true,
    //           errorMessage: `Error fetching data: ${errorObject.message}`,
    //         });
    //       });
    // }
    handleChange = (e) => {
      const { name, value } = e.target;
      
      if(name == "termsAgreement"){
        this.setState({ [name]: true });
      }else{

        this.setState({ [name]: value });
      }
      

    };

    handleSelectChange = (event) => {
      const selectedValues = Array.from(event.target.selectedOptions, option => option.value);
      this.setState({ freightItems: selectedValues });
  };

    handleSubmit = (e) => {
      e.preventDefault()
      console.log("statE::",this.state)
      const token = localStorage.getItem("userToken")
      const {
        calculatedPrice,
        termsAgreement,
        freightItems,weight,freightType} = this.state
      axios.post(`${baseUrl}/freights`,{

        freightItems,
        freightWeight:weight,
        freightType,
        totalCost:calculatedPrice*weight,
        agreeToTerms:termsAgreement
       },{
        headers:{
            "Authorization":`Bearer ${token}`
        }
      }).then(response =>{
         console.log("response::",response.data)
         console.log(" response.data.data.access_data::", response.data.access_data)
 
        
      
 
       }).catch(e => {
         
         console.log("ERROR::: ",e)
         console.log("ERROR::",e)
         this.setState({error:e.response.data.message
   
          })
       }) 
    };
  

    render() {
    const token = this.token
        console.log("customerDataList:: ",this.state)
    return (
      <React.Fragment>

        {
          token ?
          
        <div className="p-4">
          <div className="text-center text-red-600">
              {
                (!this.state.freightType || this.state.freightWeight == 0) &&
            <div className=" font-bold text-2xl md:text-6xl p-4">
              {" "}
  
              What service do you want to use ?
            </div>
          }
            
            <div className={`text-2xl text-blue-600 md:text-4xl font-bold border-4 mx-auto rounded border-white w-7/12 ${!this.state.weight &&"hidden"}`}>
              {" "}
              Price : <strong>{this.state.calculatedPrice * this.state.weight} {this.state.currency}</strong>
            </div>
          </div>
  
          <form onSubmit={this.handleSubmit} className="w-8/12 mx-auto border-2 border-gray-300  rounded p-4 mt-5">
            <div className="mb-5">
              <label
                for="freightType"
                className="block mb-2 text-sm font-medium text-gray-900 "
              >
                Freight Type
              </label>
              <select 
                name="freightType"
                onChange={this.handleChange}
                className='bg-gray-100 p-2 w-full'
              >
                <option defaultChecked>Select a Freight Type</option>
                <option value="Air Freight">Air Freight</option>
                <option value="Sea Freight">Sea Freight</option>
              </select>
             
            </div>
            <div className="mb-5">
              <label
                for="Weight"
                className="block mb-2 text-sm font-medium text-gray-900 "
              >
                Freight Weight (kg)
              </label>
              <input
                type="number"
                id="Weight"
                name="weight"
                onChange={this.handleChange}
                className="shadow-sm bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                placeholder="Enter weight eg: 200"
                min={0}
                required
              />
            </div>
            
            
            <div className="mb-5">
              <label
                for="freightItems"
                className="block mb-2 text-sm font-medium text-gray-900 "
              >
                Select an option
              </label>
              <select
                multiple
                id="freightItems"
                name="freightItems"
                required
                onChange={this.handleSelectChange}
                className="bg-gray-100 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
              >
                <option selected value={null}>Choose Item(s)</option>
                <option value="item1">item 1</option>
                <option value="item2">item 2</option>
                <option value="item3">item 3</option>
                <option value="item4">item 4</option>
              </select>
            </div>
  
            <div className="flex items-start mb-5">
              <div className="flex items-center h-5">
                <input
                  id="terms"
                  type="checkbox"
                  value=""
                  name="termsAgreement"
                  onChange={this.handleChange}
                  className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800"
                  required
                />
              </div>
              <label
                for="terms"
                className="ms-2 text-sm font-medium "
              >
                I agree with the{" "}
                <a
                  href="/terms"
                  className="text-blue-600 hover:underline dark:text-blue-500"
                >
                  terms and conditions
                </a>
              </label>
            </div>
  
            <div className='text-center'>
              <button
                type="submit"
                className={`${(!this.state.termsAgreement || !this.state.weight )? "bg-blue-100":"bg-blue-700"} mx-auto text-white  
                  hover:bg-blue-800 focus:ring-4 focus:outline-none 
                  focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 
                  text-center 
                  dark:focus:ring-blue-800`}
  
                  // disabled={!this.state.termsAgreement || !this.state.weight}
              >
                Pay for Service          
              </button>
            </div>
          </form>
        </div>
        :
          <h2>
          Not Found
          </h2>
        }
      </React.Fragment>
    );
  }
}
