import React, { Component } from "react";
import { Link, Outlet } from "react-router-dom";
import Header from "../components/Header";
import BaseSideBar from "../components/BaseSideBar";
import Nav from "../components/nav";
import axios from "axios";
import { baseUrl } from "../const_var";
import { ref, get, child } from "firebase/database";
import { database } from "../firebaseConfig";
import ShipmentDetails from "../components/ShipmentDetails";

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      success: false,
      customerDetails: {
        customerName: "",
        dateDropped: "",
        parcelWeight: "",
        status: "",
      },
    };
    this.handleGet = this.handleGet.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleReset = this.handleReset.bind(this);
  }

  componentDidMount() {
    window.scroll(0, 0);
  }

  getShippings = () => {
    axios.get(`${baseUrl}/logistics`).then((res) => {
      console.log("res", baseUrl, "res", res);
    });
  };

  handleReset() {
    this.setState({
      trackingID: "",
    });
  }
  closeModal() {
    // Close the modal and reset the state
    this.setState({
      error: false,
      success: false,
      message: "",
    });

    // Close the modal programmatically
    document.getElementById("showMessageModal")?.close();
  }
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleGet = () => {
    try {
      const mdateObj = new Date(Date.now());

      const trackingID = this.state.trackingID.split("UW");
      console.log(trackingID[0], "trackingID");

      // Step 2: Extract the year, month, and day
      const year = mdateObj.getFullYear();
      const month = mdateObj.getMonth();
      const date = mdateObj.getDate();

      const ymDateString = `${year}-${month}-${date} 00:00:00`;

      const mpdateObj = new Date(ymDateString).getTime();

      const pathRef = "uwave/" + trackingID[0] + "/-" + trackingID[1];
      // // "uwave/" + mpdateObj
      // console.log("this.pathRef:: ", pathRef);

      const dbRef = ref(database);

      // L-timeStamp-UW-
      get(child(dbRef, pathRef))
        .then((snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.val();
            console.log(data, "data");
            this.setState({
              customerDetails: {
                customerName: data.customerName,
                dateDropped: data.dateDropped,
                parcelWeight: data.parcelWeight,
              },
            });
            window.document.getElementById("showMessageModal")?.showModal();
          } else {
            // console.log("No data available");
            // this.setState({
            //   error: true,
            //   errormessage: "No data available",
            // });

            throw new Error("No data available");
          }
        })
        .catch((error) => {
          console.error(error, " ::error ");
          this.setState({
            error: true,
            errorMessage: `Error viewing shippment: , ${error}`,
          });
        });
    } catch (error) {
      console.error(error, " ::error ");
      this.setState({
        error: true,
        errorMessage: `Error viewing shippment: , ${error}`,
      });
    } finally {
      this.handleReset();
    }
  };

  render() {
    console.log("STATE.customerDetails:: ", this.state);
    return (
      <div className="container  h-screen flex flex-col items-center	 from-gray-50">
        <div className="  bg-blue pb-4 ">
          {this.state.error && (
            <div className="font-semi-bold">{this.state.errorMessage}</div>
          )}

          <h1 className="text-5xl font-bold ">
            Optimized Parcel tracking and delivery.
          </h1>
          <p className="text-md">
            Seamless Parcel tracking and delivery we have been offering.
          </p>
        </div>

        <div className="text-center py-4 bg-blue-300 rounded w-7/12">
          <input
            className=" w-9/12 text-gray p-2 border rounded mr-2"
            placeholder="Enter Tracking Number or Container Number"
            name="trackingID"
            onChange={this.handleChange}
            value={this.state.trackingID}
          />
          <button
            onClick={this.handleGet}
            className="bg-red-600 btn rounded p-2 text-white font-bold "
          >
            Track Now
          </button>
          <dialog
            id="showMessageModal"
            className={`bg-white w-9/12 border-2  rounded border-black-600 rounded text-center ${
              this.state.error
                ? "text-red-600"
                : this.state.success
                ? "text-green-600"
                : "text-black-400"
            }`}
          >
            {/* {(this.state.error || this.state.success) && ( */}
            <form
              method="dialog"
              className="modal-header p-4  justify-between items-center"
            >
              <button
                type="button"
                onClick={this.closeModal}
                className="w-2/12"
              >
                <span className="text-2xl text-red-600" aria-hidden="true">
                  &times;
                </span>
              </button>
              <div className="bg-blue-400 text-left text-white p-3 font-bold rounded ">
                <p className="p-2  ">
                  <strong className=" text-gray-700 text-sm font-bold m-2">
                    Customer Name:{" "}
                  </strong>
                  <span>{this.state.customerDetails.customerName.toUpperCase()}</span>
                </p>
                <p className="p-2  ">
                  <strong className=" text-gray-700 text-sm font-bold m-2">
                    Parcel Weight:{" "}
                  </strong>
                  <span>{this.state.customerDetails.parcelWeight}{""}KG</span>
                </p>
                <p className="p-2  ">
                  <strong className=" text-gray-700 text-sm font-bold m-2">
                    Date Dropped:{" "}
                  </strong>
                  <span>{this.state.customerDetails.dateDropped}</span>
                </p>
                <p className="p-2  ">
                  <strong className=" text-gray-700 text-sm font-bold m-2">
                    Status:{" "}
                  </strong>
                  <span>{this.state.customerDetails.status ? this.state.customerDetails.status :"UNDER-REVIEW"}</span>
                </p>
              </div>
            </form>
          </dialog>
        </div>
      </div>
    );
  }
}
