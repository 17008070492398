import Home from "./pages/Home.js";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Nav from "./components/nav.js";
import AddShipping from "./pages/AddShipping.js";
import ShippmentList from "./pages/ShippmentList.js";
import Tracking from "./pages/Tracking.js";
import ShippingCalculator from "./pages/ShippingCalculator.js";
import Dashboard from "./pages/Dashboard.js.js";
import TermsPage from "./pages/TermsPage.js";
import Login from "./pages/Login.js";
import SignUp from "./pages/SignUp.js";
import AdminLogin from "./pages/AdminLogin.js";

function App() {
  const router = createBrowserRouter([
    { path: "/", element: <Home />}, 
    {path: "/add_shipping", element: <AddShipping />},
    {path: "/shipping", element: <ShippmentList />},
    {path: "/tracking", element: <Tracking />},
    {path: "/calculator", element: <ShippingCalculator />},
    {path: "/dashboard", element: <Dashboard />},
    {path: "/login", element: <Login />},
    {path: "/admin-login", element: <AdminLogin />},
    {path: "/terms", element: <TermsPage />},
    {path: "/signup", element: <SignUp />},
  ]);

  return (
    <div className="">
      <Nav />
      <div className=" p-4 bg-red-100">
        <RouterProvider router={router} />
      </div>
    </div>
  );
}

export default App;
