

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getDatabase } from 'firebase/database';
import "firebase/firestore";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
  const firebaseConfig = {
    apiKey: "AIzaSyDjljgvxl1PT2XISU3bCDGALj9JHKhkTB0",
    authDomain: "uwave-4c3e2.firebaseapp.com",
    projectId: "uwave-4c3e2",
    storageBucket: "uwave-4c3e2.appspot.com",
    messagingSenderId: "385180965122",
    appId: "1:385180965122:web:8c08803eb63a2470af4a1b",
    databaseURL:"https://uwave-4c3e2-default-rtdb.europe-west1.firebasedatabase.app/",
    measurementId: "G-EN6QKYDK6C"
  };

// Initialize Firebase

const app = initializeApp(firebaseConfig);
 const firestore = getFirestore(app);
const database = getDatabase(app);

export { firestore,database };