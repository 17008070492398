import React, { Component } from 'react'

export default class ShipmentDetails extends Component {
    constructor(props){
        super(props)
        // this.state = this.props.details.details
    }


  render() {
    return (
        <div className="bg-black text-white p-3 border">
        <div className="font-semi-bold">
          <label>Customer Name: </label>
          <span>{this.props.details.customerName}</span>
        </div>
        <div className="font-semi-bold">
          <label>Parcel Weight: </label>
          <span>{this.props.details.parcelWeight}</span>

        </div>
        <div className="font-semi-bold">
          <label>Date Dropped: </label>
          <span>{this.props.details.dateDropped}</span>

        </div>
        <div className="font-semi-bold">
          <label>Status: </label>
          <span>{this.props.details.status}</span>

        </div>

      </div>    
    )}
}
